<template>
	<div>
		<div class="js-copy js-content pd_body">
			<headerNav :currentPath="'CryptoFi'"> </headerNav>

			<div class="f_indicator">
				<div class="f_indicator_right">
					<div class="f_indicator_item pointer" @click="next(-1)">
						<div
							class="f_indicator_pointer"
							:class="{ f_indicator_pointer_sel: this.activeTab == 0 }"
						>
						</div>
					</div>
					<div class="f_indicator_item pointer" @click="next(8)">
						<div
							class="f_indicator_pointer"
							:class="{ f_indicator_pointer_sel: this.activeTab == 1 }"
						></div>
					</div>
					<div class="f_indicator_item pointer" @click="next(9)">
						<div
							class="f_indicator_pointer"
							:class="{ f_indicator_pointer_sel: this.activeTab == 2 }"
						></div>
					</div>
					<div class="f_indicator_item pointer" @click="next(12)">
						<div
							class="f_indicator_pointer"
							:class="{ f_indicator_pointer_sel: this.activeTab == 3 }"
						></div>
					</div>
					<div class="f_indicator_item pointer" @click="next(15)">
						<div
							class="f_indicator_pointer"
							:class="{ f_indicator_pointer_sel: this.activeTab == 4 }"
						></div>
					</div>
				</div>
			</div>

			<div class="f_home f_item">
				<div class="f_home_1">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/49.png"
						alt=""
					/>
				</div>
				<div class="f_home_5">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/65.png"
						alt=""
					/>
				</div>
				<div class="f_next" @click="next(0)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/51.png"
						alt=""
					/>
				</div>
			</div>
			<div class="f_value f_item">
				<div class="f_item_header">
					<div class="f_item_header_1"> 價值觀 </div>
					<div class="f_item_header_2"> Value</div>
				</div>
				<div class="f_item_body">
					<div class="f_item_body_block"></div>
					<div class="f_item_body_text">
						解碼加密貨幣，重塑金融未來。<br />Decoding Cryptocurrency, Reshaping
						the Future of Finance.</div
					>
				</div>
				<div class="f_item_header">
					<div class="f_item_header_1"> 使命</div>
					<div class="f_item_header_2">Mission </div>
				</div>
				<div class="f_item_body">
					<div class="f_item_body_block f_item_body_block6"></div>
					<div class="f_item_body_text f_item_body_text3"
						>香港加密金融論壇致力於搭建一個促進全球加密金融行業發展的對話與合作平臺，邀請來自政府及監管部門、高校、學術研究與咨詢機構、主流金融機構及資產管理平臺的代表，以及活躍在相關領域的分析師、影響力人物和商業領袖等，探討關於加密金融、Web3技術的前沿方向與合規應用，推動整個行業的合規化、規模化發展。<br /><br />The
						CryptoFi Forum Hong Kong is committed to building a dialogue and
						cooperation platform to promote the development of the global crypto
						finance industry. It invites representatives from government and
						regulatory authorities, universities, research and consulting
						institutions, financial institutions and asset management platforms,
						as well as analysts, influencers, and business leaders active in
						related fields to discuss cutting-edge directions and applications
						of crypto finance and Web3 technology, and facilitate the compliance
						and scale development of the entire industry.</div
					>
				</div>
				<div class="f_next" @click="next(1)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_value f_item">
				<div class="f_item_header">
					<div class="f_item_header_1"> 參與群體 </div>
					<div class="f_item_header_2"> Who you can expect to meet at CFS</div>
				</div>
				<div class="f_item_body">
					<div class="f_item_body_block f_item_body_block7"></div>
					<div class="f_item_body_text f_item_body_text1">
						金融機構 /Financial institutions
						<br /><br />銀行與合規托管商、合規交易平臺、經紀商、支付平臺、保險商和金融科技公司等<br />Banks
						and custodians, regulated exchanges, brokerages, payment processors,
						insurance providers, and fintechs</div
					>
				</div>
				<div style="height: 2%"></div>
				<div class="f_item_body">
					<div class="f_item_body_block f_item_body_block7"></div>
					<div class="f_item_body_text f_item_body_text1"
						>資產管理人 /Asset Managers
						<br /><br />風險投資公司、對沖基金、資產管理平臺、家族辦公室、量化與流動性管理團隊等<br />Venture
						Capitals, hedge funds, asset management platforms, family offices,
						quant teams, and market makers
					</div>
				</div>
				<div class="f_item_body f_item_body2">
					<div class="f_item_body_block f_item_body_block8"></div>
					<div class="f_item_body_text f_item_body_text2"
						>政府及監管部門 /Government and Regulators
					</div>
				</div>

				<div class="f_item_body f_item_body1">
					<div class="f_item_body_block f_item_body_block8"></div>
					<div class="f_item_body_text f_item_body_text2"
						>高校、學術研究與咨詢機構 /Universities, research, and consulting
						institutions</div
					>
				</div>

				<div class="f_next" @click="next(2)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_themes f_item">
				<div class="f_item_header">
					<div class="f_item_header_1"> 主題 </div>
					<div class="f_item_header_2"> Themes </div>
				</div>
				<div class="f_themes_body">
					<div class="f_themes_body_left">
						<div class="f_themes_body_item">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div
									class="f_themes_body_item_name_1 f_themes_body_item_name_11"
									><span>加密資產配置者的策略</span></div
								>
								<div class="f_themes_body_item_name_2"
									>Strategy of Crypto Asset Allocators</div
								>
							</div>
							<div class="f_themes_body_item_title"
								>和風險投資、對沖基金、資產管理平臺、家族辦公室、量化團隊和做市商等主流機構一起，
								了解加密資產時代的投資組合多元化策略。</div
							>
							<div class="f_themes_body_item_text"
								>Learn portfolio diversification strategies and best practices
								in the age of crypto assets, with venture capitals, hedge funds,
								asset management platforms, family offices, quant teams, and
								market makers, etc.</div
							>
						</div>
						<div class="f_themes_body_item">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div
									class="f_themes_body_item_name_1 f_themes_body_item_name_12"
									><span>現實世界資產 (RWA) 和代幣化</span></div
								>
								<div class="f_themes_body_item_name_2"
									>Real-World Assets (RWAs) Tokenization
								</div>
							</div>
							<div class="f_themes_body_item_title"
								>討論鏈上金融資產，如貨幣、商品、股票和債券的前沿話題和最有前景的應用案例。</div
							>
							<div class="f_themes_body_item_text"
								>Discuss the frontier and most promising use cases for on-chain
								financial assets, such as currencies, commodities, equities, and
								bonds.</div
							>
						</div>
					</div>
					<div class="f_themes_body_right">
						<div class="f_themes_body_item">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div class="f_themes_body_item_name_1"
									><span>比特幣儲備</span></div
								>
								<div class="f_themes_body_item_name_2">Bitcoin Treasuries</div>
							</div>
							<div class="f_themes_body_item_title"
								>來自 BTC 持有者的觀點分享，如上市公司和私營公司、ETF
								和主權國家。</div
							>
							<div class="f_themes_body_item_text"
								>A viewpoint from BTC holders like publicly traded and private
								companies, ETFs, and countries.</div
							>
						</div>
						<div class="f_themes_body_item">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div class="f_themes_body_item_name_1"
									><span>支付和穩定幣</span></div
								>
								<div class="f_themes_body_item_name_2"
									>Payments & Stablecoins</div
								>
							</div>
							<div class="f_themes_body_item_title"
								>探索稳定币和支付体系的新版图。</div
							>
							<div class="f_themes_body_item_text"
								>Explore the Stablecoins and the New Payments Landscape</div
							>
						</div>
					</div>
				</div>

				<div class="f_next" @click="next(3)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_themes f_item">
				<div class="f_themes_body">
					<div class="f_themes_body_left">
						<div class="f_themes_body_item">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div class="f_themes_body_item_name_1"
									><span>銀行和托管</span></div
								>
								<div class="f_themes_body_item_name_2"
									>Banking & Custodians</div
								>
							</div>
							<div class="f_themes_body_item_title"
								>傳統金融巨頭和創新挑戰者如何在創建高效系統方面取得進展，
								並在加密世界中確立自己的地位。</div
							>
							<div class="f_themes_body_item_text"
								>How are traditional financial giants and new challengers making
								progress to create an efficient system and set up their
								positions in the crypto world?</div
							>
						</div>
						<div class="f_themes_body_item">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div class="f_themes_body_item_name_1"
									><span>全球監管框架</span></div
								>
								<div class="f_themes_body_item_name_2"
									>Global Regulatory Frameworks
								</div>
							</div>
							<div class="f_themes_body_item_title"
								>探讨合规框架、全球标准将如何塑造全球投资市场新格局。</div
							>
							<div class="f_themes_body_item_text"
								>Discuss the compliance frameworks, and the evolution of global
								standards that are shaping the global investment landscape.</div
							>
						</div>
					</div>
					<div class="f_themes_body_right f_themes_body_right_1">
						<div class="f_themes_body_item f_themes_body_item_1">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div class="f_themes_body_item_name_1"
									><span>區塊鏈和基礎設施</span></div
								>
								<div class="f_themes_body_item_name_2"
									>Blockchain & Infrastructure</div
								>
							</div>
							<div class="f_themes_body_item_title f_themes_body_item_title_1"
								>为加密世界构建可扩展、安全且可互操作的区块链生态系统。</div
							>
							<div class="f_themes_body_item_text f_themes_body_item_text_1"
								>Building a scalable, secure and interoperable blockchain
								ecosystem for crypto world.</div
							>
						</div>
						<div class="f_themes_body_item f_themes_body_item_1">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div class="f_themes_body_item_name_1"
									><span>去中心化金融 與AI </span></div
								>
								<div class="f_themes_body_item_name_2"
									>Decentralized Finance (DeFi) & AI</div
								>
							</div>
							<div class="f_themes_body_item_title f_themes_body_item_title_1"
								>探索去中心化金融與AI 領域的創新型應用</div
							>
							<div class="f_themes_body_item_text f_themes_body_item_text_1"
								>Explore innovative applications in DeFi and AI fields.</div
							>
						</div>

						<div class="f_themes_body_item f_themes_body_item_1">
							<div class="f_themes_body_item_line"> </div>
							<div class="f_themes_body_item_name">
								<div class="f_themes_body_item_name_1"
									><span>從經濟和金融宏觀視角看數字貨幣 </span></div
								>
								<div class="f_themes_body_item_name_2"
									>The Macro View of Economy and Finance for Digital Assets</div
								>
							</div>
							<div class="f_themes_body_item_title f_themes_body_item_title_1"
								>聆聽教授和研究人員講述經濟驅動力、利率和流動性如何影響加密貨幣世界。</div
							>
							<div class="f_themes_body_item_text f_themes_body_item_text_1"
								>Hear from the professors and researchers about how economic
								drivers, interest rates, and liquidity are impacting the crypto
								world.</div
							>
						</div>
					</div>
				</div>

				<div class="f_next" @click="next(4)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>
			<div class="f_about f_item">
				<div class="f_item_header">
					<div class="f_item_header_1"> 我们的影响力 </div>
					<div class="f_item_header_2"> Event Scale and Influence </div>
				</div>
				<div class="f_about_5">
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/6.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">10,000+ </div>
						<div class="f_about_5_item3">參與者 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/7_1.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">190+ </div>
						<div class="f_about_5_item3">演講嘉賓 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/8.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">80+ </div>
						<div class="f_about_5_item3">贊助商 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/24.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">350+ </div>
						<div class="f_about_5_item3">合作夥伴 </div>
					</div>
				</div>

				<div class="f_about_6">
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/6.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">10,000+ </div>
						<div class="f_about_5_item3">参与者 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/7_1.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">200+ </div>
						<div class="f_about_5_item3">演讲嘉宾 </div>
					</div>
				</div>

				<div class="f_about_7">
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/8.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">100+ </div>
						<div class="f_about_5_item3">参展商 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/24.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">100+ </div>
						<div class="f_about_5_item3">合作伙伴 </div>
					</div>
				</div>
				<div class="f_next" @click="next(5)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_232_prople f_item">
				<div class="f_item_header">
					<div class="f_item_header_1"> 演講嘉賓</div>
					<div class="f_item_header_2"> Featured Speakers </div>
				</div>
				<div class="f_231_5">
					<div class="f_231_5_list">
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/1-杨德斌_.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 杨德斌 </span>
							</div>
							<div class="f_231_5_item_content">
								香港特区政府数字经济发展委员会委员、大湾区国际信息科技协会香港会长
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/2-吴杰庄_.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 吴杰庄 </span>
							</div>
							<div class="f_231_5_item_content">
								全国政协委员、立法会议员兼 G-Rocket 创办人
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/3-Murzataeva Ainura.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Murzataeva Ainura </span>
							</div>
							<div class="f_231_5_item_content">
								吉尔吉斯共和国国家金融市场监理局副主席
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/4-任景信.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 任景信 </span>
							</div>
							<div class="f_231_5_item_content"> 香港数码港行政总裁 </div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/5-肖风.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 肖风 </span>
							</div>
							<div class="f_231_5_item_content">
								中国万向 控股有限公司 副董事兼执行董事、万向区块链
								实验室创始人、HashKey Group 董事长
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/6-孔剑平.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 孔剑平 </span>
							</div>
							<div class="f_231_5_item_content">
								Nano Labs
								创始人、香港数码港董事、香港第三代互联网发展专职小组成员
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/7-刘兴祥.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 刘兴祥 </span>
							</div>
							<div class="f_231_5_item_content"> 财联社副总编辑 </div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/8-蔡洪滨.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 蔡洪滨 </span>
							</div>
							<div class="f_231_5_item_content">
								香港大学 经管学院院长、中国经济研究所所长
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/9-Yat Siu.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Yat Siu </span>
							</div>
							<div class="f_231_5_item_content">
								Co-Founder and Executive Chairman of Animoca Brands
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/10-Andrew Dugree.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Andrew Dugree </span>
							</div>
							<div class="f_231_5_item_content">
								President of Republic Crypto
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/11-张鸿羽.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 张鸿羽 </span>
							</div>
							<div class="f_231_5_item_content"> 香港区块链协会副主席 </div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/people/12-吴坚.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 吴坚 </span>
							</div>
							<div class="f_231_5_item_content">
								美国律师协会中东亚及中国委员会联席主席,
								纽约亚裔律师协会亚洲执业委员联席主席
							</div>
						</div>
					</div>
				</div>
				<div class="f_next" @click="next(9)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>
			<div class="f_partners f_item">
				<div class="f_item_header">
					<div class="f_item_header_1"> 合作夥伴</div>
					<div class="f_item_header_2"> Partners </div>
				</div>

				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						:style="{ height: item.height + 'px' }"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="f_next" @click="next(6)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import Vue from "vue";
import bottom from "./pdbottom.vue";

export const newBus = new Vue();
export default {
	name: "",
	components: { headerNav, bottom },
	data() {
		return {
			osafai: false,
			tabs: ["页面一", "页面二", "页面三"], // 导航栏的选项
			activeTab: 0, // 当前激活的导航栏索引
			scrollTimeout: null, // 用于检测滚动停止
			videoUrl: "https://res.metaera.hk/resources/assets/images/forum/2024.mp4",
			posterUrl:
				"https://res.metaera.hk/resources/assets/images/forum/44_1.png",
			showOverlay: true,

			videoUrlTop:
				"https://res.metaera.hk/resources/assets/images/forum/2023.mp4",
			posterUrlTop:
				"https://res.metaera.hk/resources/assets/images/forum/21_1.png",
			showOverlayTop: true,

			partners: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/4-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/1-logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/2-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/3-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/5-logo.png",
					link: "",
					height: 70,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/6-logo.png",
					link: "",
					height: 50,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/7-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/8-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/9-logo.png",
					link: "",
					height: 70,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/10-logo.jpeg",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/11-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/12-logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/13-logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/14-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/15-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/20250103-171109.jpeg",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/17-logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/18-Certik.svg",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/19-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/20-logo.png",
					link: "",
					height: 50,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/21-logo.png",
					link: "",
					height: 80,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/22-logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/23-logo .png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/24-logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/25-logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/26-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/27-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/28-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/29-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/30-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/31-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/32-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/33-logo.png",
					link: "",
					height: 70,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/34-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/35-logo .png",
					link: "",
					height: 80,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/36-logo.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/37-logo .png",
					link: "",
					height: 70,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/38-logo .png",
					link: "",
					height: 40,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/forum/logo/39-logo .png",
					link: "",
					height: 40,
				},
			],
		};
	},
	created() {},
	mounted() {
		this.selHKLanguage();
	},

	metaInfo() {
		let descStr = "香港加密金融论坛  CryptoFi Forum HongKong";
		let title = "香港加密金融论坛  CryptoFi Forum HongKong";
		let keyword =
			"CFF，加密金融论坛，香港加密金融论坛，CryptoFi Forum ，CryptoFi Forum HongKong，金融论坛，加密金融，";
		return {
			title: title,
			meta: [
				{
					name: "keywords",
					content: keyword,
				},
				{ name: "description", content: descStr },
				{
					property: "og:description",
					content: descStr,
				},
				{
					property: "og:type",
					content: "article",
				},
				{
					property: "og:site_name",
					content: title,
				},
				{ property: "og:title", content: title },
				{
					property: "twitter:description",
					content: descStr,
				},
				{
					property: "og:image",
					content: "https://res.metaera.hk/resources/logo_share.png",
				},
				{
					property: "twitter:image",
					content: "https://res.metaera.hk/resources/logo_share.png",
				},
				{ property: "twitter:title", content: title },
			],
		};
	},
	methods: {
		selHKLanguage() {
			const index = 1;
			this.showSel = 0;
			this.localLanguage = index;
			sessionStorage.setItem("localLanguageHK", "繁体中文");
			if (this.localLanguage == 0) {
				this.$s2t(document.body);
			} else {
				this.$e2s(document.body, 1);
			}
			// 通知发送
			newBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
		next(num) {},
		top() {
			this.next(-1);
		},
		playVideo() {
			const video = this.$refs.videoPlayer;
			video.play();
		},
		playVideoYTB() {
			window.open("https://www.youtube.com/watch?v=XRiJo-XQ1Ik", "");
		},
		hideOverlay() {
			this.showOverlay = false;
		},
		playVideoTop() {
			this.$refs.videoPlayer_top.play();
		},
		playVideoYTBTop() {
			window.open("https://www.youtube.com/watch?v=JE0NHVrHnX8", "");
		},
		hideOverlayTop() {
			this.showOverlayTop = false;
		},
		handleCanPlay() {
			// 尝试自动播放
			this.$refs.videoPlayer.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},
		handleCanPlayTop() {
			// 尝试自动播放
			this.$refs.videoPlayer_top.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},
		mounted() {
			// 确保视频在加载完毕后自动播放
			this.$refs.videoPlayer.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
			this.$refs.videoPlayer_top.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},
	},
};
</script>

<!-- f_indicator -->
<style scoped>
.f_indicator_sub {
	width: 51px;
	height: 40px;
	opacity: 1;
	position: absolute;
	bottom: 10px;
	right: 10%;
	z-index: 999;
	display: block;
	align-content: center;
	align-items: center;
	text-align: center;
	display: none;
}
.f_indicator_sub_num {
	margin-left: 17px;
	font-size: 20px;
	font-weight: normal;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	color: #ffffff;
	height: 20px;
	align-content: center;
	align-items: center;
	text-align: center;
}
.f_indicator_sub_line {
	margin-top: 10px;
	margin-left: 17px;
	width: 17px;
	height: 2px;
	border-radius: 43px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;
}
.f_indicator_sub_img {
	margin-top: 20px;
	width: 51px;
	height: auto;
}
.f_indicator {
	text-align: center;
	align-content: center;
	width: 80px;
	height: 180px;
	opacity: 1;
	z-index: 999;
	position: fixed;
	top: calc(50vh - 110px);
	right: 20px;
	z-index: 999;
	/* background: orange; */
	display: flex;
	display: none;
}

.f_indicator_left {
	width: 2px;
	height: 100%;
	display: flex;
}

.f_indicator_line {
	width: 2px;
	height: 100%;
	opacity: 0.5;
	/* 纯白 */
	background: #ffffff;
}
.f_indicator_right {
	margin-top: 20px;
	width: 50px;
}

.f_indicator_item {
	height: 30px;
	width: 100%;
	display: flex;

	position: relative; /* 父容器需要相对定位，作为子元素的定位参考 */
}
.f_indicator_triangle {
	position: relative; /* 子元素1相对自己的默认位置 */
	width: 8px;
	height: 8px;
	color: white;
	display: flex;
	justify-content: center;
	top: 0px; /* 相对自身位置向下移动 */
	left: 0px; /* 相对自身位置向右移动 */
}
.f_indicator_triangle img {
	width: 100%;
	height: auto;
	object-fit: cover;
}
.f_indicator_pointer {
	opacity: 0.5;
	background: #e0eaff;
	width: 6px;
	height: 6px;
	border-radius: 3px;

	position: absolute; /* 子元素1相对自己的默认位置 */
	display: flex;
	justify-content: center;
	top: 0px; /* 相对自身位置向下移动 */
	right: 0px; /* 相对自身位置向右移动 */
}

.f_indicator_pointer_sel {
	width: 20px;
	opacity: 1;
	background: #e0eaff;
}
@media screen and (max-width: 900px) {
	.f_indicator,
	.f_indicator_sub {
		display: none;
	}
}
</style>
<style scoped>
.pd_body {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100vh;
	overflow-y: auto; /* 可滚动 */
	scroll-behavior: smooth; /* 平滑滚动 */
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: #3d29aa;
}
</style>
<style scoped>
.f_next {
	position: absolute; /* 子元素设置为绝对定位 */
	bottom: 40px; /* 距离底部为 0 */
	width: 100%; /* 可选：设置宽度为父容器宽度 */
	text-align: center;
	display: block;
	display: none;
}
.f_next img {
	width: 32px !important;
}
.f_item {
	position: relative;
	width: 100%;
	/* min-height: calc(100vh - 80px); */
	height: auto;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/5000.png");
	background-size: cover; /* 背景图片缩放适配 */
	background-position: center; /* 背景图片居中 */
	background-repeat: no-repeat; /* 不重复背景图片 */
	text-align: center;
	padding-top: 0px;
}
@media screen and (max-width: 900px) {
	.f_item {
		min-height: auto;
		height: auto;
		padding-top: 10px;
	}
	.f_next {
		bottom: 70px; /* 距离底部为 0 */
		display: none;
	}
}
</style>
<!-- f_value -->
<style scoped>
.f_value {
	text-align: start;
	/* min-height: calc(100vh - 280px); */
}
.f_item_header {
	margin-left: 10%;
	margin-top: 2%;
}

.f_item_header_1 {
	font-size: 20px;
	font-weight: normal;
	line-height: 1.5;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	opacity: 0.6;
}

.f_item_header_2 {
	font-size: 30px;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_item_body {
	margin-top: 2%;
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
}
.f_item_body1 {
	margin-top: 2%;
}
.f_item_body2 {
	margin-top: 2%;
}

.f_item_body_block {
	position: absolute;
	width: 70px;
	height: 120px;
	opacity: 1;
	z-index: 1;
	background: #6429ff;
}

@media screen and (max-width: 900px) {
	.f_value {
		margin-bottom: 50px;
		min-height: auto;
	}
	.f_item_body_block {
		width: 30px;
	}
	.f_item_body_block3 {
		height: 300px !important;
	}
	.f_item_header {
		margin-top: 20px;
	}
}
.f_item_body_block3 {
	height: 210px;
}

.f_item_body_block4 {
	height: 120px;
	width: 40px;
}

.f_item_body_block5 {
	height: 160px;
}

.f_item_body_block6 {
	height: 200px;
	position: absolute;
}
.f_item_body_block7 {
	width: 40px;
	height: 140px;
}

.f_item_body_block8 {
	width: 40px;
	height: 60px;
}
.f_item_body_text {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: 40px;
	z-index: 2;
	font-size: 30px;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_item_body_text {
		font-size: 20px;
		margin-left: 10px;
	}
	.f_item_body_text3,
	.f_item_body_text2,
	.f_item_body_text1 {
		font-size: 15px !important;
		margin-left: 10px !important;
	}
	.f_item_body_block6 {
		height: 400px;
	}
}
.f_item_body_text1 {
	font-size: 20px;
	margin-left: 20px;
}

.f_item_body_text2 {
	margin-left: 20px;
	font-size: 20px;
}

.f_item_body_text3 {
	font-size: 18px;
	margin-left: 40px;
}

@media screen and (max-width: 900px) {
	.f_item_header_1 {
		font-size: 16px;
	}
	.f_item_header_2 {
		font-size: 20px;
	}
}
</style>
<!-- f_themes -->
<style scoped>
.f_themes {
	text-align: start;
	padding-top: 100px;
	/* min-height: calc(100vh - 80px); */
}
.f_themes_body {
	margin-top: 2%;
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
	justify-content: space-between;
}

.f_themes_body_left,
.f_themes_body_right {
	width: 48%;
}

.f_themes_body_right_1 {
	display: grid;
	justify-content: space-between;
}

.f_themes_body_item {
	border-radius: 2px;
	opacity: 1;
	box-sizing: border-box;
	border: 2px solid #6454bb;
	padding: 20px;
	text-align: start;
	margin-bottom: 24px;
	min-height: 290px;
}
.f_themes_body_item_1 {
	min-height: auto;
}

.f_themes_body_item_line {
	opacity: 1;
	background: #8bffae;
	height: 4px;
	width: 40px;
}

.f_themes_body_item_name {
	margin-top: 10px;
	display: block;
	height: 60px;
}

.f_themes_body_item_name1 {
	margin-left: 10%;
	margin-top: 3%;
}

.f_themes_body_item_name_1 {
	margin-right: 10px;
	height: 30px;
}

.f_themes_body_item_name_1 span {
	padding-left: 6px;
	padding-right: 6px;
	font-size: clamp(16px, calc(100vw * 16 / 1280), 20px);
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	opacity: 1;
	background: #6429ff;
}

.f_themes_body_item_name_11 {
	min-width: 140px;
}
.f_themes_body_item_name_12 {
	min-width: 190px;
}
.f_themes_body_item_name_2 {
	font-size: clamp(8px, calc(100vw * 16 / 1280), 20px);
	font-weight: normal;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_themes_body_item_title {
	margin-top: 20px;
	font-size: clamp(12px, calc(100vw * 14 / 1280), 20px);
	font-weight: normal;
	line-height: 30px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	min-height: clamp(8px, calc(100vw * 100 / 1280), 120px);
}

.f_themes_body_item_title_1 {
	min-height: 0px;
}

.f_themes_body_item_text_1 {
	min-height: 0px !important;
}
.f_themes_body_item_text {
	font-size: clamp(12px, calc(100vw * 16 / 1280), 18px);
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: 0em;
	color: #ffffff;
	opacity: 0.6;
	min-height: clamp(60px, calc(100vw * 80 / 1280), 110px);
}
@media screen and (max-width: 900px) {
	.f_themes {
		margin-top: 40px;
		padding-top: 0px;
		min-height: auto;
	}

	.f_themes_body {
		display: block;
	}

	.f_themes_body_left,
	.f_themes_body_right {
		width: 100%;
	}
	.f_themes_body_item_name {
		margin-top: 10px;
		display: block;
		height: auto;
	}
	.f_themes_body_item_name_2 {
		font-size: 14px;
	}
	.f_themes_body_item_name_1 {
		height: auto;
	}
	.f_themes_body_item {
		min-height: 200px;
	}
}
</style>
<!-- f_home -->
<style scoped>
.f_home {
	align-content: flex-end;
	margin-top: 80px;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/64.png");
	display: flex;
	justify-content: space-between;
}

.f_home img {
	width: 100%;
	height: calc(100vh - 80px);
	object-fit: scale-down;
}

.f_home_1 img {
	width: 100%;
	height: calc(100vh - 80px);
	object-fit: contain;
}

.f_home_5 {
	width: 30%;
	margin-right: 10%;
	/* margin-bottom: 25%; */
	/* margin-top: 20%; */
	z-index: 2;
	position: relative;
}

.f_home_1 {
	width: 30%;
	margin-left: 10%;
	/* margin-bottom: 25%; */
	/* margin-top: 20%; */
	z-index: 2;
	position: relative;
}
.f_home_2 {
	width: 100%;
	margin-bottom: 50px;

	font-size: 24px;
	font-weight: normal;
	line-height: 24px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.f_home_3 {
	width: 16%;
	margin-left: 42%;
	margin-bottom: 120px;
}

@media screen and (max-width: 900px) {
	.f_home {
		margin-top: 50px;
		min-height: 80vh;
		background-image: url("https://res.metaera.hk/resources/assets/images/forum/50.png");
	}
	.f_home_5 {
		display: none;
	}
	.f_home_1 {
		width: 80%;
		margin-left: 10%;
		margin-bottom: 30%;
	}
	.f_home_2 {
		width: 100%;
		margin-bottom: 20px;
		font-size: 14px;
	}
	.f_home_3 {
		width: 40%;
		margin-left: 30%;
		margin-bottom: 60px;
	}
}
</style>
<!-- f_about -->
<style scoped>
.f_about {
	text-align: start;
	margin-bottom: 0px;
	padding-top: 100px;
	/* min-height: calc(100vh - 280px); */
}
.f_about_1 {
	margin-top: 10px;
	font-size: 30px;
	font-weight: bold;
	line-height: 30px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	z-index: 2;
	position: relative;
	margin-left: 30px;
}
.f_about_1_1 {
	margin-left: 20px;
}
.f_about_2 {
	margin-top: 20px;
	margin-left: calc(50vw - 21px);
	width: 42px;
	height: 2px;
	border-radius: 323px;
	opacity: 1;
	background: #ffffff;
}
.f_about_3 {
	margin-top: 0px;
}

.f_about_3 img {
	width: 10px;
	height: auto;
}
.f_about_4 {
	margin-top: 30px;
	width: 100%;

	display: flex;
	align-items: center;
	text-align: start;
	text-transform: uppercase;
	letter-spacing: 0em;
	color: #ffffff;
	line-height: 1.5;
	font-size: 20px;
	font-weight: 400;

	position: relative;
	margin-left: 30px;
	z-index: 2;
}
.f_about_5 {
	margin-top: 5%;
	display: flex;
	height: 300px;
	padding-left: 10%;
	padding-right: 10%;
	justify-content: space-between;
}

.f_about_6,
.f_about_7 {
	display: none;
}

.f_about_5_item {
	width: 20%;
	height: 100%;
	display: flex; /* 使用 flexbox 布局 */
	flex-direction: column;

	justify-content: space-between;
	text-align: center;
	border-radius: 2px;
	opacity: 1;
	box-sizing: border-box;
	border: 2px solid #6454bb;
}

.f_about_5_item1 {
	margin-top: 40px;
}

.f_about_5_item1 img {
	width: clamp(30px, calc(100vw * 40 / 1280), 60px);
	height: auto;
}

.f_about_5_item2 {
	width: 100%;
	font-size: clamp(20px, calc(100vw * 30 / 1280), 40px);
	font-weight: 900;
	line-height: 40px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.f_about_5_item3 {
	margin-bottom: 40px;

	font-size: clamp(16px, calc(100vw * 18 / 1280), 20px);
	font-weight: 500;
	line-height: 20px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_about {
		margin-bottom: 50px;
		padding-top: 0px;
	}
	.f_about_1 {
		margin-left: 10px;
	}
	.f_about_4 {
		margin-top: 10px;
		margin-left: 5%;
		width: 90%;
		font-size: 16px;
	}
	.f_about_5 {
		display: none;
	}

	.f_about_1_2 {
		margin-left: 10px;
	}

	.f_about_6,
	.f_about_7 {
		display: flex;
		margin-top: 20px;
		display: flex;
		height: 40%;
		padding-left: 15%;
		padding-right: 15%;
		justify-content: space-between;
	}

	.f_about_5_item {
		width: 40%;
		background: rgba(255, 255, 255, 0);
		box-sizing: border-box;
		border: 0px solid #ffffff;
	}
	.f_about_5_item1 {
		margin-top: 20px;
	}

	.f_about_5_item1 img {
		width: clamp(40px, calc(100vw * 50 / 414), 60px);
		height: auto;
	}
}
</style>

<!-- f_partners -->
<style scoped>
.f_partners {
	text-align: start;
}

.f_partners_body {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 3%;
	margin-bottom: 70px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 50px; /* 设置图片间距 */
	background: #ffffff;
	border-radius: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.f_partners_body_img {
	background: white;
	width: auto; /* 宽度自适应 */
	object-fit: cover; /* 保持图片的宽高比 */
	padding: 10px;
	border-radius: 6px;
}
</style>
<!-- f_25 -->
<style scoped>
.f_25 {
	/* min-height: calc(100vh - 80px); */
}
.f_25_1 {
	margin-top: 5%;
	height: 70%;
	margin-left: 10%;
	margin-right: 10%;
}

.f_25_1 img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
</style>

<!-- f_year_hedaer -->
<style scoped>
.f_year_hedaer {
	margin-top: 8vh;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: 10%;
	margin-right: 10%;
}

.f_year_hedaer1 {
	margin-left: 20%;
}
@media screen and (max-width: 900px) {
	.f_partners {
		min-height: auto;
	}
	.f_year_hedaer1 {
		margin-left: 10%;
	}
	.f_25_1 {
		margin-top: 5%;
		height: auto;
		margin-left: 10%;
		margin-right: 10%;
	}
}
</style>

<!-- f_23 -->
<style scoped>
.f_23_1 {
	margin-top: 20px;
	display: inline-flex;
	text-align: center;
	z-index: 2;
	position: relative;
	margin-left: 30px;
}
.f_23_1_1 {
	margin-left: 20px;
}
.f_23_1_item {
	display: flex;
	align-items: center;
}

.f_23_1_item_app {
	display: flex;
}

.f_23_1 img {
	margin-right: 10px;
	width: 20px !important;
	height: 20px !important;
}

.f_23_1 span {
	font-size: 20px;
	font-weight: normal;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.f_23_2 {
	margin-top: 30px !important;
}
.f_23_5_list {
	width: 80%;
	margin-top: clamp(30px, calc(100vw * 60 / 1280), 80px);
	display: flex;
	padding-left: 10%;
	padding-right: 10%;
	display: flex;
	flex-wrap: wrap; /* 允许子元素换行 */
	gap: 50px; /* 子元素之间的间距 */
	justify-content: space-between;
}

.f_23_5_item {
	width: 30%;
}

.f_23_5_item img {
	width: 100%;
	height: auto;
	object-fit: contain;
}
@media screen and (max-width: 900px) {
	.f_23_1 {
		margin-left: 10px;
	}
	.f_23_1_item_app {
		display: none;
	}

	.f_23_1 img {
		margin-right: 10px;
		width: 14px !important;
		height: 14px !important;
	}

	.f_23_1 span {
		font-size: 14px;
	}

	.f_23_5_list {
		width: 90%;
		margin-top: 60px;
		display: flex;
		padding-left: 5%;
		padding-right: 5%;
		display: flex;
		flex-wrap: wrap; /* 允许子元素换行 */
		gap: 30px; /* 子元素之间的间距 */
		justify-content: space-between;
	}

	.f_23_5_item {
		width: 45%;
	}
	.f_23_5_item img {
		width: 100%;
		height: auto;
	}
}
</style>

<style scoped>
.f_231,
.f_232,
.f_233 {
	text-align: start;
	/* min-height: calc(100vh - 80px); */
}

.f_232_prople {
	text-align: start;
	margin-bottom: 140px;
}

.f_23_5 {
	margin-bottom: 80px;
}

.f_231_1 {
	margin-top: 40px;
}
.f_231_2,
.f_232_2 {
	margin-top: 10px;
	display: block;
	text-align: center !important;
}
.f_232_2 {
	margin-top: 10px;
	margin-bottom: 20px;
}

.f_232_5 {
	margin-left: 20%;
	height: auto;
	width: 60%;
	height: auto;
	position: relative;
	display: block;
	margin-bottom: 0px;
	margin-top: 5%;
}

.f_232_5 video {
	height: 100%;
	width: 100%;
}

.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border-radius: 10px;
}

.video-overlay-bg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
	border-radius: 10px;
}
.bottom-left-container {
	position: absolute;
	bottom: 25px; /* 你可以根据需要调整位置 */
	left: 0px; /* 你可以根据需要调整位置 */
	border-radius: 0px 10px 10px 0px;
	background: rgba(0, 0, 0, 0.6);
}
.info-box {
	display: flex;
	align-items: center;
}
.info-text {
	margin-left: 15px;
	margin-right: 15px;
	margin-top: 15px;
	margin-bottom: 15px;
	font-family: DINPro-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.info-icon {
	margin-right: 15px;
	width: auto;
	height: 20px;
}
.video-overlay-play {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70px;
	height: auto;
	transform: translate(-50%, -50%);
}
.video-overlay-play_top {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100px;
	height: auto;
	transform: translate(-50%, -50%);
}
.f_231_5_list {
	width: 80%;
	margin-top: 3%;
	display: flex;
	padding-left: 10%;
	padding-right: 10%;
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap; /* 允许子元素换行 */
	gap: 50px; /* 子元素之间的间距 */
	margin-bottom: 0px;
}

.f_231_5_item {
	width: 20%;
}

.f_231_5_item_1 {
	width: 20%;
}

/* .f_231_5_item_1 .f_231_5_item_img img {
	height: auto;
	width: 50% !important;
	border-radius: 6px;
} */

.f_231_5_item_img img {
	height: auto;
	width: 80%;
	border-radius: 6px;
}
.f_231_5_item_name {
	margin-top: 20px;
	display: flex;
}
.f_231_5_item_name img {
	width: 10px;
	height: auto;
	object-fit: contain;
	margin-right: 6px;
}

.f_231_5_item_name span {
	font-size: clamp(12px, calc(100vw * 15 / 1280), 20px);
	font-weight: bold;
	line-height: 20px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.f_231_5_item_content {
	margin-top: 10px;
	font-size: clamp(8px, calc(100vw * 10 / 1280), 12px);
	font-weight: normal;
	line-height: 20px;
	text-align: start;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	max-width: 200px;
}
@media screen and (max-width: 900px) {
	.f_232_prople {
		margin-bottom: 40px;
	}
	.f_231_5_list {
		width: 80%;
		margin-top: 15px;
		display: flex;
		padding-left: 10%;
		padding-right: 10%;
		justify-content: space-between;
		display: flex;
		flex-wrap: wrap; /* 允许子元素换行 */
		gap: 10px; /* 子元素之间的间距 */
		margin-bottom: 60px;
	}

	.f_231_5_item {
		width: 40%;
	}

	.f_231_5_item_img img {
		height: 15vh;
		width: auto;
	}

	.f_231_5_item_name {
		margin-top: 10px;
	}

	.f_231_5_item_name img {
		width: 10px;
	}

	.f_232_2 {
		margin-bottom: 40px;
	}

	.f_231_5_item_name span {
		font-size: clamp(12px, calc(100vw * 15 / 1280), 20px);
		font-weight: bold;
		line-height: 20px;
		align-items: center;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}

	.f_231_5_item_content {
		margin-top: 10px;
		font-size: clamp(8px, calc(100vw * 10 / 1280), 12px);
		font-weight: normal;
		line-height: 20px;
		text-align: start;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
		max-width: 120px;
	}

	.f_232_5 {
		margin-left: 10%;
		height: auto;
		width: 80%;
		position: relative;
		display: inline-block;
		margin-bottom: 60px;
	}

	.f_232_5 video {
		width: 100%;
		height: auto;
	}

	.video-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		border-radius: 10px;
	}

	.video-overlay-bg {
		width: 100%;
		height: 100%;
		object-fit: cover;
		cursor: pointer;
		border-radius: 10px;
	}
	.bottom-left-container {
		position: absolute;
		bottom: 25px; /* 你可以根据需要调整位置 */
		left: 0px; /* 你可以根据需要调整位置 */
		border-radius: 0px 10px 10px 0px;
		background: rgba(0, 0, 0, 0.6);
	}
	.info-box {
		display: flex;
		align-items: center;
	}
	.info-text {
		margin-left: 15px;
		margin-right: 15px;
		margin-top: 15px;
		margin-bottom: 15px;
		font-family: DINPro-Bold;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.info-icon {
		margin-right: 15px;
		width: auto;
		height: 20px;
	}
	.video-overlay-play,
	.video-overlay-play_top {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: auto;
		transform: translate(-50%, -50%);
	}

	.bottom-left-container {
		position: absolute;
		bottom: 15px; /* 你可以根据需要调整位置 */
		left: 0px; /* 你可以根据需要调整位置 */
		border-radius: 0px 10px 10px 0px;
		background: rgba(0, 0, 0, 0.6);
	}
	.info-box {
		display: flex;
		align-items: center;
	}
	.info-text {
		margin-left: 10px;
		margin-right: 6px;
		margin-top: 10px;
		margin-bottom: 10px;
		font-family: DINPro-Bold;
		font-size: 12px;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.info-icon {
		margin-right: 10px;
		width: auto;
		height: 14px;
	}
}
</style>

<!-- f_meta -->
<style scoped>
.f_meta {
	text-align: start;
}
.f_meta_3 {
	font-size: 20px !important;
}
.f_meta_4 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 10vh;
}

.f_meta_4 span {
	font-size: 26px;
	font-weight: bold;
	line-height: 1.5;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	background: #6429ff;
	padding: 10px;
}

.f_meta_5 {
	width: 80%;
	margin-left: 10%;
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0px;
}
.f_meta_5_list_center {
	width: 200px;
}

.f_meta_5_item {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.f_meta_5_item img {
	width: 8px !important;
	height: 8px !important;
}

.f_meta_5_item span {
	margin-left: 6px;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.5;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_meta_3 {
		font-size: 16px !important;
		width: 70%;
		line-height: 2;
		margin-left: 10px;
	}

	.f_meta_4 {
		margin-left: 10%;
		margin-right: 10%;
		margin-top: 10vh;
	}

	.f_meta_4 span {
		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
		background: #6429ff;
		padding: 10px;
	}

	.f_meta_5 {
		width: 80%;
		margin-left: 10%;
		margin-top: 30px;
		display: flex;
		justify-content: space-around;
		margin-bottom: 60px;
	}

	.f_meta_5_list_center {
		width: 10%;
	}

	.f_meta_5_item {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
	.f_meta_5_item img {
		width: 8px !important;
		height: 8px !important;
	}

	.f_meta_5_item span {
		margin-left: 6px;
		font-size: 12px;
		font-weight: 600;
		line-height: 18px;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}
}
</style>

<!-- f_contact -->
<style scoped>
.f_contact {
	display: none;
}
.f_contact_2 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 8vh;
}
.f_contact_1 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 5vh;
	margin-bottom: 100px;
}

.f_contact_1_item img {
	background: white;
	padding: 4px;
	border-radius: 4px;
	object-fit: contain;
}

.f_contact_1 span {
	font-size: 20px;
	font-weight: normal;
	line-height: 30px;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	text-transform: lowercase;
}

.f_contact_3 {
	transform: rotate(180deg); /* 旋转 180 度 */
}
@media screen and (max-width: 900px) {
	.f_contact_2 {
		margin-top: 5vh;
	}
	.f_contact_1 {
		margin-top: 5vh;
		margin-bottom: 10vh;
		height: 100px;
		display: inline-block;
		text-align: center;
		align-items: center;
		align-content: center;
	}
}
</style>
